<template>
  <div>
    <import-login-details
      :user-type="'Pupil'"
    >
    </import-login-details>
  </div>
</template>

<script>

// Components
import ImportLoginDetails from '../components/import/import.vue';

// Icon mdiInformationOutline

export default {
  components: {
    ImportLoginDetails,
  },
  setup() {
    return {
      ImportLoginDetails,
    }
  },
}
</script>

<style>
.v-item-group.theme--dark.v-slide-group.v-tabs-bar.v-tabs-bar--is-mobile.v-tabs-bar--show-arrows.primary--text {
  background: transparent
}
</style>
